<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="activityName"
                    label="活动名称"
                >
                    <el-input
                        v-model="queryFormModel.activityName"
                        placeholder="请输入活动名称"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/gd/fulfilGive/fulfilGiveFullGive/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="activityName"
                    label="活动名称"
                    min-width="100"
                />
                <el-table-column
                    label="活动开始时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.activityBeginTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="活动结束时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.activityEndTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="activityDesc"
                    label="活动描述"
                    min-width="100"
                />
                <el-table-column
                    prop="isAvailable"
                    label="是否可用"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isAvailable? '是':'否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/gd/fulfilGive/fulfilGiveFullGive/edit/' + scope.row.id)"
                        >
                            编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'FulfilGiveFullGive',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                activityName: '',
                fulfilGiveType: 3,
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Pm.FulfilGive.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
